import { toast } from 'react-toastify';

import { useLayout } from '@/provider/LayoutProvider';
import { AVATAR_DEFAULT, useAuth } from '@/provider/UserProvider';
import { api } from '@/services';
import { signatureAuthen, signInWithSso } from '@/services/authentication';
import storageFunction from '@/utils/storage';
import { convertUrlToFile } from '@/utils';
import { updateAvatarProfile } from '@/services/provider';

export function useConnectSsoWallet() {
  const { setIsLoadingBar } = useLayout();
  const { saveToken } = storageFunction;
  const { setAuth } = useAuth();

  const handleUploadAvatarDefault = async () => {
    const avatarLinkRandom = AVATAR_DEFAULT[Math.floor(Math.random()*AVATAR_DEFAULT.length)];
    const file = await convertUrlToFile(avatarLinkRandom || '', 'avatar');
    const formData = new FormData();
    formData.append('picture', file, 'profile');
    const resUser = await updateAvatarProfile(formData);

    if (resUser.data?.picture) return resUser.data.picture;
    return '';
  }

  const connectSsoWallet = async (userSso: any) => {
    setIsLoadingBar(true);
    try {
      const userLogin = await signInWithSso(userSso.accessTokenSso);
      const { accessToken, refreshToken } = userLogin.token;
      if (accessToken && refreshToken) {
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        saveToken(accessToken, refreshToken);

        let avatar = userLogin.picture;
        if (!avatar) {
          const ava = await handleUploadAvatarDefault();
          avatar = ava;
        }

        setAuth({
          user: {
            ...userLogin,
            id: userLogin?.id,
            username: userLogin.username ?? 'Un Name',
            picture: avatar,
            publicAddress: userLogin?.publicAddress ?? '',
            childKey: null,
            description: userLogin.description ?? '',
            email: userSso?.user?.Provider[0]?.name, // email
            isPlus: userLogin.isPlus,
            refCode: userLogin.refCode,
            credit: 0,
            sVerify: userLogin.sVerify,
            avatarDecoration: userLogin?.avatarDecoration || null,
            usedRef: {
              username: userLogin.usedRef?.username,
              publicAddress: userLogin.usedRef?.publicAddress,
              picture: userLogin.usedRef?.picture,
              refCode: userLogin.usedRef?.refCode,
            },
          },
          isOwallet: false,
          accessToken,
          refreshToken,
        });
      }

      setIsLoadingBar(false);
    } catch (error: any) {
      if (error) {
        setIsLoadingBar(false);
        toast.error(error.message);
      }
    }
  };

  return { connectSsoWallet };
}

export function useSignature() {
  const { setIsLoadingBar } = useLayout();
  const { saveToken } = storageFunction;
  const { setAuth } = useAuth();

  const signature = async (
    walletAddressOrai: string,
    nonce: string,
    signature: string,
    publicKey: string,
    user: string,
  ) => {
    try {
      setIsLoadingBar(true);

      const chainId = 'Oraichain';
      const signDoc = {
        account_number: '0',
        chain_id: '',
        fee: { gas: '0', amount: [] },
        memo: '',
        msgs: [
          {
            type: 'sign/MsgSignData',
            value: {
              data: nonce,
              signer: walletAddressOrai,
            },
          },
        ],
        sequence: '0',
      };

      const postData = {
        signed: signDoc,
        chain_id: chainId,
        nonce: Buffer.from(nonce, 'base64').toString('utf8'),
        keplr: true,
        username: 'Un Name',
      };

      const result = await signatureAuthen(postData, signature, publicKey);

      const { accessToken, refreshToken } = result.token;
      if (accessToken && refreshToken) {
        saveToken(accessToken, refreshToken);

        setAuth({
          user: {
            ...result,
            id: result.id,
            username: result.username,
            picture: result.picture,
            publicAddress: walletAddressOrai,
            childKey: null,
            description: result.description ?? '',
            email: user, // email
            isPlus: result.isPlus,
            credit: 0,
            sVerify: result.sVerify,
            avatarDecoration: result?.avatarDecoration || null,
          },
          isOwallet: false,
          accessToken,
          refreshToken,
        });

        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      }

      setIsLoadingBar(false);
    } catch (error: any) {
      setIsLoadingBar(false);
      toast.error(error.message);
    }
  };

  return { signature };
}
