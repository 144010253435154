import { Modal, ModalBody, ModalContent } from '@nextui-org/react';
import React from 'react';
import { Buttons } from '../button';
import Image from 'next/image';
import { AllowPopup } from '@/assets';

const BlockPopup: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
}> = ({ isOpen, onOpenChange }) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="center"
      hideCloseButton
    >
      <ModalContent className="w-[500px] max-w-full">
        {(onClose) => (
          <ModalBody className="px-[30px] gap-0 py-[32px] sm:px-5 sm:py-5">
            <h4 className="font-semibold text-[16px] mb-3">Allow pop-ups to login</h4>
            <p className="text-[14px]">To ensure our site functions correctly, please allow pop-ups by following:</p>
            <Image className="mt-2" alt="allow" src={AllowPopup} />
            <div className="flex mt-5 justify-end">
              <Buttons onClick={onClose}>Close</Buttons>
            </div>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}

export default BlockPopup;