import { Button, Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react';
import React, { useCallback, useEffect, useState } from 'react';

import { FarmingAiriIcon, LoginGoogleIcon, LoginOwalletIcon } from '@/assets';
import { configs } from '@/configs';
import { useConnectKeplrWallet } from '@/hooks/useConnectKeplrWallet';
import { useConnectSsoWallet } from '@/hooks/useConnectSsoWallet';
import { useAuth } from '@/provider/UserProvider';
import { changeOwnerArtFree } from '@/services';
import { isJSON, popupCenter } from '@/utils';
import BlockPopup from '@/components/block-popup';
import cachedLocalStorage from '@/libs/localStorage';

const initMsg = {
  walletAddressOrai: '',
  accessTokenSso: '',
  user: {
    Provider: [
      {
        avatar: '',
        createdAt: '',
        id: 9,
        name: '',
        provider: '',
        providerId: '',
        type: '',
        updatedAt: '',
        userId: 9,
      },
    ],
    createdAt: '',
    evmWallet: '',
    name: '',
    provider: '',
    oraiWallet: '',
    status: '',
    updatedAt: '',
  },
};

const LoginModal: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
  onClose: () => void;
}> = ({ isOpen, onOpenChange, onClose }) => {
  const [msg, setMsg] = useState(initMsg);
  const { connectKeplrWallet } = useConnectKeplrWallet();
  const { connectSsoWallet } = useConnectSsoWallet();
  const { isLogin, auth } = useAuth();
  const { isOpen: isOpenP, onOpenChange: onOpenChangeP, onOpen: onOpenP } = useDisclosure();

  const callbackPopupBlock = () => {
    onClose();
    onOpenP();
  };

  const login = () => {
    setMsg(initMsg);
    popupCenter({
      url: `${configs.ssoUrl}/google-signin`,
      title: 'Login Google',
      w: 380,
      h: 550,
      callbackPopupBlock,
    });
  };

  const handleConnectKeplrSso = useCallback(
    async (msg: any) => {
      await connectSsoWallet(msg);
    },
    [msg?.accessTokenSso],
  );

  const ListLogin = [
    {
      title: 'Continue with Google',
      icon: <LoginGoogleIcon />,
      className: 'bg-white border border-gray-200',
      onClick: login,
    },
    {
      title: 'Connect Owallet',
      icon: <LoginOwalletIcon />,
      className: 'bg-[#935AFF] text-white',
      onClick: connectKeplrWallet,
    },
  ];

  useEffect(() => {
    if (msg.walletAddressOrai) {
      handleConnectKeplrSso(msg);
    }
  }, [msg?.walletAddressOrai]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== configs.ssoUrl) return;

          if (event.data) {
            if (isJSON(event.data)) {
              const msg = JSON.parse(event.data);
              if (msg?.response?.accessTokenSso && msg.response.user) {
                setMsg(msg.response);
              }
            }
          }
        },
        false,
      );
    }
    return window.removeEventListener('message', () => {});
  }, []);

  useEffect(() => {
    if (!isLogin) {
      setMsg(initMsg);
    }
  }, [isLogin]);

  const handelChangeOwner = async () => {
    const listProcessId = JSON.parse(cachedLocalStorage?.getItem('listAssetId') || '[]');

    if (listProcessId[0] > 0) {
      const res = await changeOwnerArtFree(listProcessId, Number(auth.user.id));
      if (res) {
        cachedLocalStorage.removeItem('listAssetId');
      }
    }
  };

  useEffect(() => {
    if (isLogin && cachedLocalStorage?.getItem('listAssetId')) {
      handelChangeOwner();
    }
  }, [isLogin]);

  return (
    <>
      <BlockPopup isOpen={isOpenP} onOpenChange={onOpenChangeP} />
      <Modal
        className=" w-[368px] rounded-[32px]"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
        hideCloseButton
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="px-[44px] pb-[32px] pt-[44px]">
                <div className="flex flex-col items-center justify-center">
                  <div className="mx-0 mb-10 flex flex-col items-center justify-center px-0 text-center">
                    <FarmingAiriIcon className="mb-5" />
                    <p className="mx-0 px-0 text-center text-24 font-semibold">
                      Welcome to aiRight
                    </p>
                    <p className="mx-0 px-0 text-14 font-normal text-gray-500">Continue with</p>
                  </div>
                  {ListLogin.map((item, index) => (
                    <Button
                      onClick={item.onClick}
                      onPress={onClose}
                      className={`mx-0 mb-3 h-[48px] w-[280px] rounded-[100px] px-0 text-14 font-semibold ${item.className}`}
                      key={index}
                    >
                      {item.icon} {item.title}
                    </Button>
                  ))}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginModal;
