import { isNil } from 'lodash';
import { toast } from 'react-toastify';

import { useLayout } from '@/provider/LayoutProvider';
import { AVATAR_DEFAULT, useAuth } from '@/provider/UserProvider';
import { api } from '@/services';
import { getNonce, signatureAuthen } from '@/services/authentication';
import { convertUrlToFile, makeid } from '@/utils';
import storageFunction from '@/utils/storage';
import { updateAvatarProfile } from '@/services/provider';

export function useConnectKeplrWallet() {
  const { setIsLoadingBar } = useLayout();
  const { saveToken } = storageFunction;
  const { setAuth } = useAuth();

  const handleUploadAvatarDefault = async () => {
    const avatarLinkRandom = AVATAR_DEFAULT[Math.floor(Math.random()*AVATAR_DEFAULT.length)];
    const file = await convertUrlToFile(avatarLinkRandom || '', 'avatar');
    const formData = new FormData();
    formData.append('picture', file, 'profile');
    const resUser = await updateAvatarProfile(formData);

    if (resUser.data?.picture) return resUser.data.picture;
    return '';
  }

  const connectKeplrWallet = async () => {
    try {
      setIsLoadingBar(true);
      const keplr = await window.Keplr.getKeplr();
      const { chainId } = window.Wasm.cosmos;

      if (keplr && window.Keplr) {
        await window.Keplr.suggestChain(chainId as never);
      } else {
        toast.error('You must install OWallet or Keplr to continue!');
        return;
      }

      const key = await window.Keplr.getKeplrKey(chainId);

      let nonce = await getNonce(key.bech32Address);

      if (isNil(nonce)) {
        nonce = makeid(8);
      }

      const signDoc = await window.Keplr.getFixedAminoSignDoc(
        key.bech32Address,
        Buffer.from(JSON.stringify({ nonce })).toString('base64'),
      );

      const response = await window.keplr.signArbitrary(
        chainId,
        key.bech32Address,
        JSON.stringify({ nonce }),
      );

      const postData = {
        signed: signDoc,
        chain_id: chainId,
        nonce,
        keplr: true,
        username: key?.name,
      };

      if (response.signature && response.pub_key.value) {
        const result = await signatureAuthen(postData, response.signature, response.pub_key.value);
        const { accessToken, refreshToken } = result.token;

        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        saveToken(accessToken, refreshToken);

        let avatar = result.picture;
        if (!avatar) {
          const ava = await handleUploadAvatarDefault();
          avatar = ava;
        } 

        setAuth({
          user: {
            ...result,
            id: result.id,
            username: result.username,
            picture: avatar,
            publicAddress: key.bech32Address,
            childKey: null,
            description: result.description ?? '',
            email: result?.email ?? '',
            isPlus: result.isPlus,
            refCode: result.refCode,
            credit: 0,
            sVerify: result.sVerify,
            avatarDecoration: result?.avatarDecoration || null,
            usedRef: {
              username: result.usedRef?.username,
              publicAddress: result.usedRef?.publicAddress,
              picture: result.usedRef?.picture,
              refCode: result.usedRef?.refCode,
            },
          },
          accessToken,
          refreshToken,
          isOwallet: true,
        });
        setIsLoadingBar(false);
      }
    } catch (error: any) {
      if (error) {
        if (error.message === "Cannot read properties of undefined (reading 'signature')") {
          toast.warning('Approve to continue');
        } else {
          toast.error(error.message);
        }
        setIsLoadingBar(false);
      }
    }
  };

  return { connectKeplrWallet };
}
